<template lang="pug">
  div.d-flex.flex-column
      HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :dateRangeDefault="dateRangeDefault" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow" :indexSearch="indexSearch" :homepageMode="homepageMode" :homepage="homepage")
      .d-flex.w-100.h-100(style="overflow: hidden;")
        .text-center.flex-center.w-100(v-if='isLoadingRemindersListByCollaborator || !headerLoaded || !dataLoaded')
          .loading-bg-inner
              .loader
                .outer
                .middle
                .inner
          .mt-5
              br
              br
              br
              | Chargement de la liste des relances commerciales...
        sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :editSettings='editSettings'  :allowGrouping="allowGrouping" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked"  @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" @actionBegin="actionBegin" :archive="archive" :archiveMode="archiveMode" @deleteButtonClicked="deleteButtonClicked" @archiveButtonClicked="archiveButtonClicked" @restoreButtonClicked="restoreButtonClicked")
  </template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

export default {
  components: {
    SyncGrid,
    HeaderGrid,
  },
  data() {
    return {
      homepageMode: true,
      homepage: false,
      conlumnsOrigin: [],
      indexSearch: 0,
      name: "reminder",
      uid: "myReminders",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],
      dateRangeDefault: [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")),
      ],
      dataLoaded: false,
      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      archiveMode: false,
      searchPlaceholder: "Rechercher une relance commerciale",
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: false,
        mode: "Dialog",
        titleAdd: "Nouvelle activité commerciale",
        titleEdit: "Modifier l'activité commerciale",
        titleField: "activityDate",
      },
      allowGrouping: true,
    };
  },
  computed: {
    ...mapGetters([
      "userCollaboratorId",
      "collaboratorsList",
      "companiesList",
      "contactsList",
      "activityChanelTypesList",
      "activityResultTypesList",
      "activitySourceTypesList",
      "isLoadingRemindersListByCollaborator",
      "workspaceSelected",
    ]),
    dataFiltered() {
      return this.$store.getters["remindersListByCollaborator"];
    },
  },
  watch: {
    $route: {
      handler: async function (to, from) {
        if (to.name == "my-reminders") {
          this.headerLoaded = false;
          await this.getRemindersByCollaboratorId({
            collaboratorId: this.userCollaboratorId,
          }).then(async (res) => {
            this.setHeaderData();
            if (res.length) {
              await this.setColumnsByObject(res[0], null, this);
              this.indexSearch++;
            }
            this.headerLoaded = true;
          });
        }
      },
      deep: true,
      immediate: false,
    },
  },
  async created() {
    Promise.all([
      this.getCollaborators({}),
      this.getCompanies({}),
      this.getContacts({}),
      this.getActivityChanelTypes({}),
      this.getActivityResultTypes({}),
      this.getActivitySourceTypes({}),
    ]).then(() => {
      this.dataLoaded = true;
    });
  },
  async activated() {
    this.headerLoaded = false;
    await this.getRemindersByCollaboratorId({
      collaboratorId: this.userCollaboratorId,
    }).then(async (res) => {
      this.setHeaderData();
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
        this.indexSearch++;
      }
      this.headerLoaded = true;
    });
  },
  methods: {
    ...mapActions([
      "getCollaborators",
      "getCompanies",
      "getActivityChanelTypes",
      "getActivityResultTypes",
      "getActivitySourceTypes",
      "getRemindersByCollaboratorId",
      "createActivity",
      "updateActivity",
      "archiveActivities",
      "deleteActivities",
      "restoreActivities",
      "getContacts",
    ]),
    formatCurrency,
    setColumnsByObject,
    async setHeaderData() {
      this.headerData = [
        {
          type: "checkbox",
          headerText: null,
          width: 30,
          maxWidth: 30,
          visible: false,
          showInColumnChooser: false,
        },
        {
          type: "date",
          field: "reminderDate",
          headerText: "Date relance",
          width: 140,
          maxWidth: 140,
          format: "dd/MM/yyyy HH:mm",
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          allowGrouping: false,
          showInColumnChooser: true,
          visible: true,
          defaultValue: new Date(),
          editType: "datetimepickeredit",
          direction: "descending",
        },
        {
          type: "number",
          field: "collaboratorId",
          headerText: "Réalisé par",
          filterTemplate: "collaboratorFilterTemplate",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          foreignKeyValue: "id",
          dataSource: this.collaboratorsList,
          edit: {
            source: this.collaboratorsList,
            fields: { text: "displayLabel", value: "id" },
            type: "dropdown", //<<FIX
            name: "dropdowncollaborator",
          },
          // editType: "dropdownedit",
        },
        {
          type: "number",
          field: "companyId",
          filterTemplate: "companyFilterTemplate",
          headerText: "Société",
          allowFiltering: true,
          allowSorting: false,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          edit: {
            source: this.companiesList,
            fields: { text: "name", value: "id" },
            type: "dropdowndependencies",
            name: "dropdowncompany",
            target: "dropdowncontact",
          },
          validationRules: { required: true },
        },
        {
          type: "number",
          field: "contactId",
          headerText: "Interlocuteur(trice)",
          filterTemplate: "contactFilterTemplate",
          allowFiltering: true,
          allowSorting: false,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          edit: {
            source: this.contactsList,
            fields: {
              text: "displayLabel",
              value: "id",
            },
            type: "dropdown", //<<FIX
            name: "dropdowncontact",
          },
          // editType: "dropdownedit",
        },
        {
          type: "number",
          field: "activitySourceTypeId",
          filterTemplate: "activitySourceFilterTemplate",
          headerText: "Source",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          dataSource: this.activitySourceTypesList,
          edit: {
            source: this.activitySourceTypesList,
            fields: { text: "label", value: "id" },
            type: "dropdown", //<<FIX
            name: "activitySourceTypeId",
          },
          // editType: "dropdownedit",
        },
        {
          type: "number",
          field: "activityChanelTypeId",
          filterTemplate: "activityChanelFilterTemplate",
          headerText: "Canal",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          dataSource: this.activityChanelTypesList,
          edit: {
            source: this.activityChanelTypesList,
            fields: { text: "label", value: "id" },
            type: "dropdown", //<<FIX
            name: "activityChanelTypeId",
          },
          // editType: "dropdownedit",
        },
        {
          type: "number",
          field: "activityResultTypeId",
          filterTemplate: "activityResultFilterTemplate",
          headerText: "Résultat",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          dataSource: this.activityResultTypesList,
          edit: {
            source: this.activityResultTypesList,
            fields: { text: "label", value: "id" },
            type: "dropdown", //<<FIX
            name: "activityResultTypeId",
          },
          // editType: "dropdownedit",
        },
        {
          type: "string",
          field: "comments",
          headerText: "Commentaire",
          minWidth: 300,
          allowFiltering: true,
          allowSorting: true,
          allowGrouping: false,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          clipMode: "EllipsisWithTooltip",
          template: "stripHtmlTemplate",
          edit: { type: "textarea", name: "comments" },
          filter: { type: "Menu", operator: "contains" },
        },
        {
          type: "boolean",
          field: "isUnreachable",
          headerText: "NRP",
          width: 90,
          maxWidth: 90,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "boolean",
          editType: "booleanedit",
        },
        {
          type: "boolean",
          field: "hasLeavingMessage",
          headerText: "Message laissé",
          width: 90,
          maxWidth: 90,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "boolean",
          editType: "booleanedit",
        },
        {
          type: "date",
          field: "activityDate",
          headerText: "Date origine",
          width: 140,
          maxWidth: 140,
          format: "dd/MM/yyyy HH:mm",
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          allowGrouping: false,
          showInColumnChooser: true,
          visible: true,
          defaultValue: new Date(),
          editType: "datetimepickeredit",
        },
      ];
    },
    async actionBegin(args) {
      if (args.requestType === "save" && args.action === "add") {
        // A VERIFIER (BAPTISTE) - Rafraichissement de la grid
        await this.createActivity({
          activity: args.data,
          route: "collaborator",
        });
        this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      }
      if (args.requestType === "save" && args.action === "edit") {
        await this.updateActivity({
          activity: args.data,
          route: "collaborator",
        });
        this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      }
      if (args.requestType === "beginEdit") {
        this.conlumnsOrigin = JSON.parse(
          JSON.stringify(
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()
          )
        );
        for (
          var i = 0;
          i < this.$refs.overviewgrid.$refs.overviewgrid.getColumns().length;
          i++
        ) {
          if (
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[i].field ==
            "id"
          ) {
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[
              i
            ].visible = false;
          } else {
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[
              i
            ].visible = true;
          }
        }
      }
      if (args.requestType === "save" || args.requestType === "cancel") {
        //On affiche les colonnes de userparameters
        for (var i = 0; i < this.conlumnsOrigin.length; i++) {
          this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[i].visible =
            this.conlumnsOrigin[i].visible;
        }
      }
    },
    clickAction() {
      this.$router.push({
        name: "new-activity",
        params: { routeOrigine: "directory/my-activities" },
      });
    },
    onRowClicked(event) {
      //this.$refs.overviewgrid.selectRow(event.rowIndex, false)
      // this.$refs.overviewgrid.$refs.overviewgrid.startEdit();
    },
    async archiveButtonClicked(args) {
      // A VERIFIER (BAPTISTE) - Rafraichissement de la grid
      await this.archiveActivities({
        activityIds: [args.rowData.id],
        route: "collaborator",
      });
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    async restoreButtonClicked(args) {
      // A VERIFIER (BAPTISTE) - Rafraichissement de la grid
      await this.restoreActivities({
        activityIds: [args.rowData.id],
        route: "collaborator",
      });
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    async deleteButtonClicked(args) {
      // A VERIFIER (BAPTISTE) - Rafraichissement de la grid
      await this.deleteActivities({
        activityIds: [args.rowData.id],
        route: "collaborator",
      });
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.archive = true;
      } else if (args.item.type == "current") {
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
  },
};
</script>
